<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div v-if="!isDefault" class="addAddress" @click="goAddressList()">
      <p>请添加收货地址</p>
      <img class="arrowR" src="../../static/giftIndex/arrowR.png" alt>
      <img class="solids" src="../../static/giftIndex/solid.png" alt>
    </div>
    <div v-else class="show_address" @click="goAddressList()">
      <div>
        <p class="receiver">
          {{ receiver }}
          <span>{{ telephone }}</span>
        </p>
        <p class="detail_info">{{ address }}</p>
        <!--  -->
        <div v-if="nosend" class="notMailing">
          该地址暂不支持发货，请重新选择！
        </div>
      </div>
      <img class="right_arrow" src="../../static/giftIndex/arrowR.png" alt>
      <img class="bottom_img_line" src="../../static/giftIndex/solid.png" alt>
    </div>
    <div class="main">
      <dl>
        <dt>
          <img :src="banner_image" alt>
        </dt>
        <dd>
          <p class="title">{{ obj.gift_name }}</p>
          <p class="crrent">已选：{{ colorMsg }} {{ GuigMsgName }}</p>
          <div class="prices">
            <img src="../../static/ucenter-qie/mon.png" alt>
            <p>{{ obj.quantity }}</p>
          </div>
        </dd>
      </dl>
    </div>
    <div v-show="gift_source !== 2" class="clsColorSize">
      <div class="classification">
        <p class="classification_p">颜色</p>
        <div class="classification_item">
          <p
            v-for="(item,index) in colors"
            :key="index"
            :class="{greyP:index !== myIndex,redP:index === myIndex}"
            @click="choiceColor(item,index)"
          >{{ item }}</p>
        </div>
      </div>
      <div v-show="Guig && Guig.length" class="classification">
        <p class="classification_p">规格</p>
        <div class="classification_item">
          <p
            v-for="(item,index) in Guig"
            :key="index"
            :class="{greyP:index !== myIndex1,redP:index === myIndex1}"
            @click="choiceGuig(item,index)"
          >{{ item.specs }}</p>
        </div>
      </div>
    </div>
    <div class="num">
      <p class="quantity">数量</p>
      <div class="stepper">
        <p class="kc">库存{{ myStock }}件</p>
        <van-stepper v-model="value" disabled />
      </div>
    </div>
    <!-- 支付方式 -->
    <div v-if="payTypes.length&&realMoney" class="bgc_fff margin_top">
      <div class="title">支付方式</div>
      <van-radio-group v-model="payType">
        <van-cell-group>
          <van-cell v-for="item in payTypes" v-show="checkShowPayType(item)" :key="item.id" clickable @click="payType = item.id">
            <template #title>
              <div class="jus-start pay_type_box">
                <div class="pay_img">
                  <img :src="item.pic" alt srcset>
                </div>
                <div class="custom-title">{{ item.name }}支付</div>
              </div>
            </template>
            <template #right-icon>
              <van-radio checked-color="#EE2E2E" :name="item.id" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!-- 金额明细 -->
    <section v-if="realMoney" class="pay_way money-detail bgc_fff margin_top">
      <p class="title">金额明细</p>
      <div class="price">
        <div v-for="item in addcounts" :key="item.name" class="price_item">
          <div class="price_titile">兑换所需{{ scoreName }}</div>
          <div class="price_text">{{ obj.quantity }}{{ scoreName }}</div>
        </div>
        <div v-for="item in addcounts" :key="item.name" class="price_item">
          <div class="price_titile">{{ item.name }}价格</div>
          <div class="price_text">￥{{ item.price }}</div>
        </div>
      </div>
    </section>

    <div class="myFoot">
      <div class="price">
        <span class="payMsg">共支付：</span>
        <img v-if="!realMoney" src="../../static/ucenter-qie/mon.png" alt>
        <p v-if="obj.quantity&&!realMoney">{{ obj.quantity*value }}</p>
        <p v-if="realMoney">￥{{ realMoney }}</p>
        <!-- <div v-for="item in addcounts" :key="item.name" class="mailBox">
          <div class="costName">{{ item.name }}价格</div>
          <div class="price_text">￥{{ item.price }}</div>
        </div> -->
      </div>
      <div class="immediatelyExchange">
        <button @click="RedeemNow()">立即兑换</button>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { Toast } from 'vant'
import isWxMini from '@/utils/isWechat.js'
import tool from '@/utils/tools.js'
import Cookies from 'js-cookie'
import WechatState from '@/utils/isWechatState.js'
import { getGiftMsg, getSpecifications, get_giftDetail, getAddressList, PayImmediately, get_list, getMyScore, getexpensesOption, isSuccess } from '@/api/my_user'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '填写兑换信息',
      value: 1,
      colors: [],
      myIndex: null,
      colorMsg: '',
      Guig: [],
      GuigMsg: '',
      GuigMsgName: '',
      myIndex1: null,
      myStock: 0,
      // 1 原创、 2 京东
      gift_source: '',
      obj: {},
      banner_image: '',
      isDefault: false,
      receiver: '',
      telephone: '',
      address: '',
      address_id: '',
      addcounts: [],
      nosend: 0,
      realMoney: '',
      payType: isWxMini ? 7 : 2,
      payTypes: [],
      webSocket: null,
      uid: parseInt(Cookies.get('uid') || '0')
    }
  },
  mounted() {
    this.getMsg()
    this.get_msg()
    sessionStorage.removeItem('clickIndex') // 京东添加地址死循环处理
    // this.addressList()
    // socket
    if (WechatState.isWechatMiniprogram) {
      if (this.checkCanUserSocket()) {
        this.createSocket()
      }
    } else {
      // 给两次机会检测，暂时这样
      setTimeout(() => {
        if (this.checkCanUserSocket()) {
          this.createSocket()
        } else {
          setTimeout(() => {
            if (this.checkCanUserSocket()) {
              this.createSocket()
            }
          }, 1000)
        }
      }, 1000)
    }
  },
  beforeDestroy() {
    this.closeSocket()
  },
  methods: {
    // 测试提示
    canShowTips() {
      const cookie_uid = Cookies.get('uid') || ''
      if (this.uid == 15292296 || cookie_uid == 8588314) {
        return true
      }
      return false
    },
    // 判断显示支付方式
    checkShowPayType(i) {
      if (isWxMini) {
        if (i.id === 7) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    // 获取邮费信息
    getMailCost() {
      var info = {
        product_type: 13,
        // mobile: this.userTel,
        product_id: this.$route.query.id,
        address_id: this.address_id
      }
      getexpensesOption(info).then((res) => {
        if (res.errNo == 0) {
          // console.log(res, 'pppp')
          this.addcounts = res.result.money.addcounts
          this.nosend = res.result.nosend
          this.payTypes = res.result.payTypes
          this.realMoney = res.result.realMoney
        }
      })
    },
    // 跳转地址列表
    goAddressList() {
      if (this.gift_source === 1) {
        // 原创地址
        this.$router.push({
          path: '/index/express/address_management',
          query: { select: 1 }
        })
      } else {
        // JD
        this.$router.push({
          path: '/jdAddressList',
          query: { select: 1 }
        })
      }
    },
    // 京东地址获取
    getJdAddressList() {
      getAddressList().then(res => {
        const result = res.result
        var is_Default = false
        is_Default = result.list.some((item) => {
          return item.is_default
        })
        var item_adds = []
        if (is_Default) {
          item_adds = result.list.filter((item) => {
            return item.is_default
          })
        }
        if (item_adds !== undefined && item_adds.length > 0) {
          this.isDefault = true
          const item = item_adds[0]
          this.receiver = item.receiver
          this.telephone = item.mobile
          this.address = item.address_json.province + item.address_json.city + item.address_json.county + item.detail_address
          this.address_id = item.id
        }
        this.getMailCost()
      })
    },
    // 获取原创地址列表
    getZLAddressLists() {
      get_list().then(res => {
        const result = res.result
        var is_Default = false
        is_Default = result.list.some((item) => {
          return item.is_default
        })
        var item_adds = []
        if (is_Default) {
          item_adds = result.list.filter((item) => {
            return item.is_default
          })
        }
        if (item_adds !== undefined && item_adds.length > 0) {
          this.isDefault = true
          const item = item_adds[0]
          this.receiver = item.receiver
          this.telephone = item.mobile
          this.address = item.province + item.city + item.county + item.detail_address
          this.address_id = item.id
        }
        this.getMailCost()
      })
    },
    choiceGuig(item, index) {
      this.myIndex1 = index
      this.GuigMsg = item.id
      this.GuigMsgName = item.specs
      this.myStock = item.num
    },
    choiceColor(item, index) {
      this.myIndex = index
      this.colorMsg = item
      console.log(this.colorMsg)
      var params = {
        gift_id: this.$route.query.id,
        color: this.colorMsg
      }
      getSpecifications(params).then(res => {
        this.Guig = res.result
        if (res.result.length === 1) {
          this.GuigMsg = res.result[0].id
        }
      })
    },
    getMsg() {
      var params = {
        id: this.$route.query.id
      }
      getGiftMsg(params).then(res => {
        this.colors = res.result.specs
        this.gift_source = res.result.gift_source

        // 调 不同的接口
        if (this.gift_source === 1) {
          // 原创
          const result = JSON.parse(sessionStorage.getItem('selectAdd'))
          if (result) {
            this.isDefault = true
            this.receiver = result.receiver
            this.telephone = result.mobile
            this.address = result.province + result.city + result.county + result.detail_address
            this.address_id = result.id
            sessionStorage.removeItem('selectAdd')
            this.getMailCost()
          } else {
            this.getZLAddressLists()
          }
        } else {
          const item = JSON.parse(sessionStorage.getItem('selectAddJD'))
          if (item) {
            this.isDefault = true
            this.receiver = item.receiver
            this.telephone = item.mobile
            this.address = item.address_json.province + item.address_json.city + item.address_json.county + item.detail_address
            this.address_id = item.id
            sessionStorage.removeItem('selectAddJD')
            this.getMailCost()
          } else {
            // 京东
            this.getJdAddressList()
          }
        }
      })
    },
    get_msg() {
      var params = {
        id: this.$route.query.id
      }
      get_giftDetail(params).then(res => {
        console.log(res)
        this.obj = res.result
        this.gift_source = this.obj.gift_source
        this.value = res.result.purchase_num
        this.myStock = this.obj.num
        if (this.obj.gift_source === 2) {
          this.banner_image = 'http://img13.360buyimg.com/n12/' + this.obj.img
        } else {
          this.banner_image = this.obj.img
        }
      })
    },
    RedeemNow() {
      getMyScore().then(res => {
        console.log(res.result, this.obj.quantity * this.value)
        if (res.result >= this.obj.quantity * this.value) {
          if (this.gift_source === 2) {
            // 京东的，不显示颜色和规格
            if (this.myStock === 0) {
              Toast('没有库存了')
            } else if (this.nosend) {
              Toast('该地区不在销售范围内')
            } else {
              var params = {
                id: this.$route.query.id,
                gift_source: this.gift_source,
                num: this.value,
                remark: '',
                specs_id: this.GuigMsg,
                address_id: this.address_id,
                pay_type: this.payType
              }
              PayImmediately(params).then(res => {
                if (res.errNo !== 0) {
                  Toast(res.msg)
                } else {
                  var dataInfo = {
                    mobile: '',
                    product_type: res.result.product_type,
                    pay_type: this.payType,
                    product_id: res.result.product_id,
                    address_id: this.address_id
                  }
                  // 判断是否在app、小程序内
                  if (this.realMoney > 0 && tool.isInApp()) {
                    // 金额大于0 且 在app 处理APP支付放在此处
                    const path =
                    'mobile=' +
                    '' +
                    '&product_type=' +
                    res.result.product_type +
                    '&pay_type=' +
                    this.payType +
                    '&product_id=' +
                    res.result.product_id +
                    '&address_id=' +
                    this.address_id
                    this.goAppPay(path)
                  } else if (this.realMoney > 0 && (this.checkCanUserSocket() && this.webSocket)) {
                    // 小程序内嵌h5的支付
                    this.sendCreateOrderDatas(dataInfo)
                  } else {
                    Toast('兑换成功')
                    this.$router.push({
                      path: '/SuccessfulExchange'
                    })
                  }
                }
              })
            }
          } else {
            if (!this.GuigMsgName) {
              Toast('请选择颜色与规格')
            } else if (this.myStock === 0) {
              Toast('没有库存了')
            } else if (this.nosend) {
              Toast('该地区不在销售范围内')
            } else {
              var params1 = {
                id: this.$route.query.id,
                gift_source: this.gift_source,
                num: 1,
                remark: '',
                specs_id: this.GuigMsg,
                address_id: this.address_id,
                pay_type: this.payType
              }
              PayImmediately(params1).then(res => {
                if (res.errNo !== 0) {
                  Toast(res.msg)
                } else {
                  var dataInfo = {
                    mobile: '',
                    product_type: res.result.product_type,
                    pay_type: this.payType,
                    product_id: res.result.product_id,
                    address_id: this.address_id
                  }
                  // 判断是否在app、小程序内
                  if (this.realMoney > 0 && tool.isInApp()) {
                    // 金额大于0 且 在app 处理APP支付放在此处
                    const path =
                    'mobile=' +
                    '' +
                    '&product_type=' +
                    res.result.product_type +
                    '&pay_type=' +
                    this.payType +
                    '&product_id=' +
                    res.result.product_id +
                    '&address_id=' +
                    this.address_id
                    this.goAppPay(path)
                  } else if (this.realMoney > 0 && (this.checkCanUserSocket() && this.webSocket)) {
                    // 小程序内嵌h5的支付
                    if (this.canShowTips()) {
                      Toast('11111小程序环境')
                      console.log('11111小程序环境')
                    }
                    this.sendCreateOrderDatas(dataInfo)
                  } else {
                    if (res.result.isSuccess == 1) {
                      Toast('兑换成功')
                      this.$router.push({
                        path: '/SuccessfulExchange'
                      })
                    } else {
                      location.href = res.result.pay_url
                    }
                  }
                }
              })
            }
          }
        } else {
          Toast('筑龙币不足')
        }
      })
    },
    // 检测是否可以开启socket
    checkCanUserSocket() {
      if (typeof WebSocket === 'undefined') {
        return false
      }
      const uid = Cookies.get('uid')
      if (uid === undefined) {
        return false
      }
      const app_code = Cookies.get('app_code')
      if (WechatState.isWechatMiniprogram && app_code) {
        return true
      }
      return false
    },
    // 开启socket
    createSocket() {
      this.webSocket = new WebSocket('wss://m.zhulong.com/wss_socket/')
      this.webSocket.onopen = this.webSocketOnOpen
      this.webSocket.onmessage = this.webSocketGetMessage
      this.webSocket.onerror = this.webSocketOnError
      this.webSocket.onclose = this.webSocketClosed
    },
    // 链接
    webSocketOnOpen(res) {
      console.log('open ', res)
      // 登录
      const loginStr = this.createForSocketMessage('login', null)
      console.log('loginStr ', loginStr)
      this.socketSendDatas(loginStr)
    },
    // 收到消息
    webSocketGetMessage(res) {
      const message = JSON.parse(res.data)
      console.log('收到消息 ', message)
      switch (message.type) {
        case 'login_end':
          console.log('登录成功')
          break
        case 'mini_productPaySuccess':
        // 小程序支付成功
          const app_code = Cookies.get('app_code')
          const get_app_code = message.app_code
          if (app_code == get_app_code) {
            const data = JSON.parse(message.data)
            this.socketPaySuccess(data)
          }
          break
        default:
          break
      }
    },
    // 失败
    webSocketOnError(res) {
      console.log('失败 ', res)
      this.createSocket()
    },
    // 关闭
    webSocketClosed(res) {
      console.log('关闭 ', res)
      if (res.code != 1000) {
      // 非正常关闭
        this.createSocket()
      }
    },
    // 发消息
    socketSendDatas(jsonInfo) {
      this.webSocket.send(jsonInfo)
    },
    // 关闭socket
    closeSocket() {
      this.webSocket.close()
      this.webSocket = null
    },
    // 获取登录的字符串,info：object
    createForSocketMessage(type, info) {
      const uid = Cookies.get('uid')
      const appid = Cookies.get('app_id')
      const agency_id = Cookies.get('agency_id')
      const uidStr = agency_id + '_' + appid + '_' + uid + '_2'
      var login = {
        type: type,
        uid: uidStr
      }
      // 自定义数据
      if (info) {
        login.data = JSON.stringify(info)
      }
      if (this.canShowTips()) {
        Toast('-------------createForSocketMessage')
        console.log('-------------uidStr', uidStr)
      }
      return JSON.stringify(login)
    },
    // 发下单
    sendCreateOrderDatas(query) {
      console.log(query)
      // 区分哪个小程序
      const app_code = Cookies.get('app_code')
      const order = {
        type: 'mini_productOrderPay',
        app_code: app_code,
        paydata: JSON.stringify(query)
      }
      var infoStr = this.createForSocketMessage('proxy', order)
      if (this.canShowTips()) {
        Toast('22222sendCreateOrderDatas')
        console.log('222222app_code', app_code)
      }
      console.log(infoStr)
      this.socketSendDatas(infoStr)
    },
    // 小程序支付成功 {odersn: "order12330303"}
    socketPaySuccess(e) {
      console.log(e)
      const order = e.odersn
      this.jumpSuccess(order)
    },
    // 跳转成功页——APP使用
    jumpSuccess(order_sn) {
      console.log('成功order ', order_sn)
      // 检测订单状态
      isSuccess({ order_sn: order_sn }).then((res1) => {
        if (res1.errNo == 0) {
          const result = res1.result || {}
          result.isSuccess = 1
          this.$router.push({
            path: './paySuccess',
            query: { str: JSON.stringify(res1.result) }
          })
        }
      })
    },
    // go APP支付
    goAppPay(url) {
      if (tool.isInIosApp()) {
        window.webkit.messageHandlers.vue_payProduct.postMessage(url)
      } else {
        this.$jsbridge.callHandler(
          'vue_payProduct',
          url,
          function(responseData) {
          // android 返参数接受
          }
        )
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.payMsg {
  position: absolute;
  left: 70px;
  top: 30px;
  color: #666666;
  font-size: 24px;
  letter-spacing: 2px;
}
.immediatelyExchange button {
  width: 300px;
  height: 100px;
  background: #ee2e2f;
  color: #fff;
  font-size: 32px;
}
.myFoot {
  width: 300px;
  height: 100px;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
}
.myFoot .price img {
  position: absolute;
  width: 41px;
  height: 40px;
  left: 165px;
  top: 28px;
}
.myFoot {
  width: 100%;
  height: 100px;
}
.myFoot .price p {
  margin: 0 auto;
  line-height: 90px;
  width: 114px;
  height: 37px;
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #ee2e2f;
  padding: 0 0 0 100px;
}
.myFoot .price {
  width: 450px;
  height: 100px;
  background: #fff;
  position:relative;
  .mailBox{
    width: 100%;
    text-align: center;
    position:absolute;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#ee2e2e
  }
}

.stepper {
  display: flex;
  margin: 27px 50px 0 0;
}
.quantity {
  color: #222222;
  font-size: 30px;
  font-weight: 600;
  line-height: 112px;
  padding-left: 34px;
}
.kc {
  color: #999999;
  font-size: 24px;
  padding: 10px 15px 0 0;
}
.num {
  width: 100%;
  height: 112px;
  background: #fff;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.classification {
  padding-bottom: 25px;
}
.classification_p {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #000000;
  margin: 0 30px;
}
.classification_item {
  display: flex;
  width: 93%;
  margin: 0 auto;
  flex-wrap: wrap;
  // justify-content: space-between;
  .greyP {
    width: 152px;
    background: #f7f7f7;
    border: 2px solid #f7f7f7;
    border-radius: 6px;
    color: #666666;
    text-align: center;
    line-height: 62px;
    margin-top: 10px;
    margin-left: 15px;
  }
  .redP {
    width: 152px;
    background: #ffefef;
    border: 2px solid #ff7576;
    color: #ee2e2f;
    border-radius: 6px;
    text-align: center;
    line-height: 62px;
    margin-top: 10px;
    margin-left: 15px;
  }
}
.clsColorSize {
  width: 100%;
  // height: 350px;
  margin: 20px 0 0 0;
  background: #fff;
}
.crrent {
  color: #555555;
  font-size: 24px;
  padding: 10px 0 0 0;
}
.title {
  font-size: 30px;
  color: #111111;
  padding: 47px 0 0 0;
}
.prices {
  display: flex;
  margin: 18px 0 0 0;
  img {
    width: 39px;
    height: 38px;
    position: relative;
    top: 6px;
  }
  p {
    color: #ee2e2f;
    font-size: 46px;
    font-weight: 600;
    padding: 0 0 0 20px;
  }
}
dl {
  display: flex;
  dt img {
    width: 180px;
    height: 180px;
    background: antiquewhite;
    margin: 30px 0 0 45px;
  }
  dd {
    margin: 0 0 0 20px;
  }
}
.main {
  width: 100%;
  min-height: 240px;
  background: #fff;
  margin: 28px 0 0 0;
}
.arrowR {
  width: 15px;
  height: 24px;
  padding: 45px 30px 0 0;
}
.addAddress {
  width: 100%;
  height: 112px;
  background: #fff;
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  p {
    color: #333;
    font-size: 34px;
    padding: 0 0 0 29px;
    line-height: 112px;
    letter-spacing: 2px;
  }
}
.solids {
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.show_address {
  width: 100%;
  background: #fff;
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  .receiver {
    font-size: 30px;
    padding: 30px 0 0 30px;
    span {
      color: #999;
      font-size: 30px;
      padding-left: 20px;
    }
  }
  .detail_info {
    font-size: 34px;
    color: #333;
    padding: 20px 0 20px 30px;
    width: 579px;
    line-height: 55px;
  }
  .right_arrow {
    width: 15px;
    height: 24px;
    padding: 100px 30px 0 0;
  }
  .bottom_img_line {
    width: 100%;
    height: 6px;
    position: absolute;
    bottom: -8px;
    left: 0;
  }
}
.notMailing{
    color: #ee2e2e;
    font-size: 24px;
    text-align: left;
    font-family: SourceHanSansSC-regular;
    margin-top:-20px;
    padding-left: 30px;
  }

  .margin_top {
  margin-top: 20px;
  .title{
    font-size: 26px;
    color: #222222;
    letter-spacing: 0;
    padding: 20px;
    font-weight: 600;
  }
}
.pay_type_box {
  padding: 20px 0;
}
.jus-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
// .title {
//   font-family: PingFangSC-Medium;
//   font-size: 26px;
//   color: #222222;
//   letter-spacing: 0;
//   padding: 20px;
// }
.pay_img {
  margin-right: 10px;
  img {
    display: block;
    max-height: 40px;
    max-width: 100px;
  }
}
.bgc_fff {
  background-color: #fff;
}
.pay_way{
  padding-bottom: 120px;
}
.price_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  color: #999;
  font-size: 24px;
  padding: 20px;
  .price_titile {
    font-size: 24px;
  }
}
</style>
